import { useMsal } from "@azure/msal-react";
import { AppBar, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { loginRequest } from "../../authConfig.js";
import ChatwootWidget from "../ChatWidget.jsx";
import AlertDialog from "../ErrorDialog.jsx";

export default function Index({
  setAccessToken,
  withBackgroundNavbar = true,
  accessToken,
}) {
  const [open, setOpen] = React.useState(false);
  const [isChatwootReady, setIsChatwootReady] = React.useState(false);
  const location = useLocation();
  const endsInBis = location.pathname.endsWith("-bis");
  const { instance, accounts } = useMsal();

  useEffect(() => {
    function handleChatwootReady() {
      setIsChatwootReady(true);
    }

    if (window.$chatwoot) {
      setIsChatwootReady(true);
    } else {
      window.addEventListener("chatwoot:ready", handleChatwootReady);
    }

    return () => {
      window.removeEventListener("chatwoot:ready", handleChatwootReady);
    };
  }, []);

  useEffect(() => {
    if (accounts.length === 0) {
      // No accounts available, handle the scenario appropriately
      return;
    }
    const request = {
      ...loginRequest,
      redirectUri: `${window.location.protocol}//${window.location.host}/blank`,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch(() => {
        instance
          .acquireTokenPopup(request)
          .then((response) => {
            setAccessToken(response.accessToken);
          })
          .catch((error) => {
            console.error(error);
            if (error.errorCode === "popup_window_error") {
              setOpen(true);
            }
          });
      });
  }, [accounts]);

  useEffect(() => {
    if (accounts.length > 0 && isChatwootReady && accessToken) {
      const browserLanguage = navigator.language;
      window.$chatwoot.setLocale(browserLanguage);
      window.$chatwoot.setConversationCustomAttributes({
        current_url: window.location.href,
      });
      fetch("https://chat-verify.bb.vision", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: accounts[0].homeAccountId,
          accessToken: accessToken,
        }),
      })
        .then((response) => {
          response
            .json()
            .then((data) => {
              window.$chatwoot.setUser(accounts[0].homeAccountId, {
                email: accounts[0].username,
                name: accounts[0].name,
                identifier_hash: data.hash,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accounts, isChatwootReady, accessToken]);

  return (
    <Box sx={{ width: "100vw" }}>
      <ChatwootWidget />
      <AlertDialog open={open} />
      <AppBar
        position="static"
        className="gray-navbar"
        elevation={0}
        sx={{ height: "calc(100vh / 22)", backgroundColor: "#41424c" }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: 4,
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="body1" sx={{ marginX: 1 }}>
            BB Media | Portal
          </Typography>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            Home
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/Shared%20Documents/Forms/AllItems.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            Reports
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/_layouts/15/news.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            News
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/Report-Bugs.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1 }}
            variant="caption"
          >
            Bugs
          </Link>
          <Link
            href="https://businessbureau0.sharepoint.com/sites/BB-Portal/SitePages/About-BB.aspx"
            color="inherit"
            underline="none"
            sx={{ marginX: 1, width: "80px" }}
            variant="caption"
          >
            About BB
          </Link>
        </Box>
      </AppBar>
      {endsInBis ? (
        <AppBar
          position="static"
          className="gray-navbar"
          elevation={0}
          sx={{ height: "calc(100vh / 22)", backgroundColor: "red" }}
        >
          <Box
            sx={{
              display: "flex",
              marginLeft: 4,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="body2" sx={{ marginX: 1 }}>
              You are seeing the dashboard on an alternative back-up server.
              Speed may be slower.
            </Typography>
          </Box>
        </AppBar>
      ) : null}
      {withBackgroundNavbar ? (
        <AppBar
          position="absolute"
          className="navbar"
          sx={{
            backgroundColor: "rgb(12, 34, 67)",
            marginTop: "calc(100vh / 22)",
            height: "calc(100vh / 11.4285714286)",
            zIndex: -1,
          }}
        ></AppBar>
      ) : null}
      <Outlet />
    </Box>
  );
}

Index.propTypes = {
  setReport: PropTypes.func,
  accessToken: PropTypes.string,
  setAccessToken: PropTypes.func.isRequired,
  withBackgroundNavbar: PropTypes.bool,
};
