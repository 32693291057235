import { useMsal } from "@azure/msal-react";
import { Box, Grid, Stack } from "@mui/material";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import PropTypes from "prop-types";
import { loginRequest } from "../authConfig";

import React, { useState } from "react";
import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_EMBED_FIT,
  DEFAULT_EMBED_WIDTH,
} from "../constants.js";

const Embed = ({
  id,
  accessToken,
  groupId,
  fit = DEFAULT_EMBED_FIT,
  width = DEFAULT_EMBED_WIDTH,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
}) => {
  const [visible, setVisible] = useState(false);
  const fitUpperCased = fit.charAt(0).toUpperCase() + fit.slice(1);

  const { instance, accounts } = useMsal();

  const getNewAccessToken = async () => {
    if (accounts.length === 0) {
      console.error("No accounts available.");
      return null;
    }
    const request = {
      ...loginRequest,
      redirectUri: `${window.location.protocol}//${window.location.host}/blank`,
      account: accounts[0],
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      console.error("Error acquiring token silently", error);
      try {
        const popupResponse = await instance.acquireTokenPopup(request);
        return popupResponse.accessToken;
      } catch (popupError) {
        console.error("Error acquiring token with popup", popupError);
        return null;
      }
    }
  };
  return (
    <Grid
      justifyContent={"center"}
      sx={{ width: "100%", display: "flex", backgroundColor }}
    >
      {visible ? null : (
        <Stack
          sx={{
            zIndex: 1000,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
        >
          <img width={274} src="/loading.gif" alt="loading" />
          {/* <CircularProgress />
          <p>Loading report...</p> */}
        </Stack>
      )}
      <Box
        sx={{
          visibility: visible ? "visible" : "hidden",
          width,
        }}
      >
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id,
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${id}&groupId=${groupId}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUNFTlRSQUwtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19`,
            accessToken,
            tokenType: models.TokenType.Aad,
            eventHooks: {
              accessTokenProvider: getNewAccessToken,
            },
            pageView: fit,
            settings: {
              bars: {
                actionBar: {
                  visible: false,
                },
              },
              layoutType: models.LayoutType.Custom,
              customLayout: {
                displayOption: models.DisplayOption[fitUpperCased],
              },
              panes: {
                bookmarks: {
                  visible: false,
                },
                fields: {
                  expanded: false,
                },
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: true,
                },
                selection: {
                  visible: false,
                },
                syncSlicers: {
                  visible: false,
                },
                visualizations: {
                  expanded: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  setVisible(true);
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.error(event);
                  setVisible(true);
                },
              ],
            ])
          }
          cssClassName={"report-style-class"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </Box>
    </Grid>
  );
};

export default Embed;

Embed.propTypes = {
  id: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  embedUrl: PropTypes.string,
  fit: PropTypes.string,
  width: PropTypes.string || PropTypes.number,
  backgroundColor: PropTypes.string,
  warning: PropTypes.bool,
};
